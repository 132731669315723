export const getRoutePathWithPrefix = (path) => {
  if (
    process.env.REACT_APP_ENV === "qa" ||
    process.env.REACT_APP_ENV === "local"
  )
    return "/" + path + "/";
  return "/" + path + "/";
};

export const asyncActionHelper = (actionName) =>
  typeof actionName !== "string"
    ? new Error("Please provide a string to actionName!")
    : {
        actions: {
          STARTED: `${actionName}_STARTED`,
          SUCCEEDED: `${actionName}_SUCCEEDED`,
          FAILED: `${actionName}_FAILED`,
        },
        dispatchers: {
          started: () => ({ type: `${actionName}_STARTED` }),
          succeeded: (payload) => ({
            type: `${actionName}_SUCCEEDED`,
            payload: payload,
          }),
          failed: (error) => ({ type: `${actionName}_FAILED`, payload: error }),
        },
      };

export const basePosterPath = (() => {
  let src;
  switch (process.env.REACT_APP_ENV) {
    case "development":
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/OCP_Calypso_2024_Poster_National.pdf?Expires=1807280059&Signature=pkMZ4C60oI6gEj8WsS3GGBSsWT5hap3H4rm1PBOUfRzybHLeJ~fBWsj4G1Qvv4nzDFq~89mJfnoKnAg-5HRe73dYjRQFmz7RHya~yCprVc~qvvunRFn0h2DvTTjv~p5oWd5g27FwmQHiDuOptfVRoQiViESKh65YPnTBEENs6nx3oW5LjCqXgHaQa1O8Lbn0PZOWuiDb8SyWrFv6JlT5LoQjYJYY0UZhiPxOWwiNrTmcr0x9N2mAcSbaVlMYWUZ5AgpKDyuzEqtZYmnjbDJ9SNDy91Bm-ZupFmpy8wXzNX0r4I4oy1ockEw~ou1NEG2U-PhHBgkW~ycGmeVL-7GpMw__&Key-Pair-Id=K23EPIM3SQEY3Y";
      break;
    case "qa":
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/OCP_Calypso_2024_Poster_National.pdf?Expires=1807280059&Signature=pkMZ4C60oI6gEj8WsS3GGBSsWT5hap3H4rm1PBOUfRzybHLeJ~fBWsj4G1Qvv4nzDFq~89mJfnoKnAg-5HRe73dYjRQFmz7RHya~yCprVc~qvvunRFn0h2DvTTjv~p5oWd5g27FwmQHiDuOptfVRoQiViESKh65YPnTBEENs6nx3oW5LjCqXgHaQa1O8Lbn0PZOWuiDb8SyWrFv6JlT5LoQjYJYY0UZhiPxOWwiNrTmcr0x9N2mAcSbaVlMYWUZ5AgpKDyuzEqtZYmnjbDJ9SNDy91Bm-ZupFmpy8wXzNX0r4I4oy1ockEw~ou1NEG2U-PhHBgkW~ycGmeVL-7GpMw__&Key-Pair-Id=K23EPIM3SQEY3Y";
      break;
    case "prod":
      src =
       "https://d1snzrtxgdo8c5.cloudfront.net/OCP/2024/UI/OCP_Calypso_2024_Poster_National.pdf?Expires=1807972331&Signature=A0Mt8No9aetR51bTczSlelOKq6T0F6InfGWj2teptMbJyFW55yGf18OxswMlBUBsCcVOWQhUynfxYe2oLymAP3zc~1AJAoiuTxgg3ITGKs47eW7wn0xxloyKx~DYxXEV-iK7q8WrS2QHR7V8gJLQeIv6oP4BcrZZhryV25Q~gDrEtzhgMbRP~i77TYjJVVFTQ4ZvMpgRuBtFldR-3iJhpMNWkl5hmS4p2c2YODU4cVMhS9EWf1J1Tq9WzHg23HzREVso6PiUHhWSobk0yj8G7CsC9COkHQgmOptMVMIRuqfvIgdnWH-8nGPsozWWV3nYjb6rw0UQUn2hRT5U7-517A__&Key-Pair-Id=K3Q7KU9K8DFKFP";
      break;
    default:
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/OCP_Calypso_2024_Poster_National.pdf?Expires=1807280059&Signature=pkMZ4C60oI6gEj8WsS3GGBSsWT5hap3H4rm1PBOUfRzybHLeJ~fBWsj4G1Qvv4nzDFq~89mJfnoKnAg-5HRe73dYjRQFmz7RHya~yCprVc~qvvunRFn0h2DvTTjv~p5oWd5g27FwmQHiDuOptfVRoQiViESKh65YPnTBEENs6nx3oW5LjCqXgHaQa1O8Lbn0PZOWuiDb8SyWrFv6JlT5LoQjYJYY0UZhiPxOWwiNrTmcr0x9N2mAcSbaVlMYWUZ5AgpKDyuzEqtZYmnjbDJ9SNDy91Bm-ZupFmpy8wXzNX0r4I4oy1ockEw~ou1NEG2U-PhHBgkW~ycGmeVL-7GpMw__&Key-Pair-Id=K23EPIM3SQEY3Y";
  }
  return src;
})();

export const baseQuestionnairePath = (() => {
  let src;
  switch (process.env.REACT_APP_ENV) {
    case "development":
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/Questionnaire_HI_Barometre_satisfaction_OCP_2024.pdf?Expires=1807280059&Signature=jwI7thAoZ1KEyK44qVwwNHFMBv4fPGYAiLS~3HYn9LWObbwlvlDlKKfdZdg24EHC~9LAsHRb2U9N0RQVm-rBQIjKedfQ12IYe2QK9NjYThACmsuD0I03eMsRTTEANatq~0C~h5G3ApDXJS4aRKCgFimYb6DcQzE5scRWK4tydfpQJmOP91MTwwq3i734t4g7uI7cxu1OHljKDqe7aM1dELUOGUbhICINcOPw~gmReSKTM9Pgwrk2kttlPVZI5I7bDlhE85xUmWe3hXIYUJnFIyoLA-rSmeQCqiRE7gw8WFTlRxjTOcjkwOVtR3Aplc3jYH0yKL7SZBWeMdrnAR5sQg__&Key-Pair-Id=K23EPIM3SQEY3Y";
      break;
    case "qa":
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/Questionnaire_HI_Barometre_satisfaction_OCP_2024.pdf?Expires=1807280059&Signature=jwI7thAoZ1KEyK44qVwwNHFMBv4fPGYAiLS~3HYn9LWObbwlvlDlKKfdZdg24EHC~9LAsHRb2U9N0RQVm-rBQIjKedfQ12IYe2QK9NjYThACmsuD0I03eMsRTTEANatq~0C~h5G3ApDXJS4aRKCgFimYb6DcQzE5scRWK4tydfpQJmOP91MTwwq3i734t4g7uI7cxu1OHljKDqe7aM1dELUOGUbhICINcOPw~gmReSKTM9Pgwrk2kttlPVZI5I7bDlhE85xUmWe3hXIYUJnFIyoLA-rSmeQCqiRE7gw8WFTlRxjTOcjkwOVtR3Aplc3jYH0yKL7SZBWeMdrnAR5sQg__&Key-Pair-Id=K23EPIM3SQEY3Y";
      break;
    case "prod":
      src =
        "https://d1snzrtxgdo8c5.cloudfront.net/OCP/2024/UI/Questionnaire_HI_Barometre_satisfaction_OCP_2024.pdf?Expires=1807972331&Signature=UEr0HsgpqEpmaMtqJpPcZifXCt-0iiEQw-MpLcmL7052YCBXJ4NWp-HzGYvgTtwpixVvEaUGxfk~u1AMsPHY6ipkPUzH1xNCDSO0NkIZyYOCTxX0KpMYcb25KnAS~5q~EQOqVCw-GX5PWzk6YcT11z1j1ZJtFLzs-W9CsvJjWT6Lye3DwBu8W1tHfXekl3fZod~i2sxTlAqz2HtKsepsuYDW6a000zkApFRQUXN53egwBnL5g5W588wrepitCSu0L69-khS5BXqf-S6t4SkeMT-MnnsQwi2~-rVvEYa2s9HzCOsJO3uzJkCf~Tgr6p8lJTkzPvCa46pYOc3z0XdK-w__&Key-Pair-Id=K3Q7KU9K8DFKFP";
      break;
    default:
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/Questionnaire_HI_Barometre_satisfaction_OCP_2024.pdf?Expires=1807280059&Signature=jwI7thAoZ1KEyK44qVwwNHFMBv4fPGYAiLS~3HYn9LWObbwlvlDlKKfdZdg24EHC~9LAsHRb2U9N0RQVm-rBQIjKedfQ12IYe2QK9NjYThACmsuD0I03eMsRTTEANatq~0C~h5G3ApDXJS4aRKCgFimYb6DcQzE5scRWK4tydfpQJmOP91MTwwq3i734t4g7uI7cxu1OHljKDqe7aM1dELUOGUbhICINcOPw~gmReSKTM9Pgwrk2kttlPVZI5I7bDlhE85xUmWe3hXIYUJnFIyoLA-rSmeQCqiRE7gw8WFTlRxjTOcjkwOVtR3Aplc3jYH0yKL7SZBWeMdrnAR5sQg__&Key-Pair-Id=K23EPIM3SQEY3Y";
  }
  return src;
})();

export const baseRaportPath = (() => {
  let src;
  switch (process.env.REACT_APP_ENV) {
    case "development":
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/OCP_Calypso_2024-Rapport_National.pptx?Expires=1808141055&Signature=cl2H7Mbx8UI-Ullbl49GfZE5n1AciFSuycgb1rZIzZXIiU~K~iOuuG~ZkruznFWlDiO-s3QSnp8jVMcvJkBdB-jCqk95sjuA~Gm3O5kcnob8QlU4iIuS~vS6JVied6A-3-xgIfI-bNviCsbfDAUSvj2aOUuQbHY14LnY-5mnjBnDHEdzJmQKPdxcsQ-FdPINz4dgQ1AYw0sut8XJ9OwIz7J9nGPCNIzg2gaAEGS1VYlmPImI1tB3z8MHRUwJ2qb36OKa6NemN38OsyTzpkKhZINGtUoVHH6F3~vjmyOYilm-8IwuoGjj6zCh0pNKU0ViyWw8sD0knXIiQNbO5vhyvw__&Key-Pair-Id=K23EPIM3SQEY3Y";
      break;
    case "qa":
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/OCP_Calypso_2024-Rapport_National.pptx?Expires=1808141055&Signature=cl2H7Mbx8UI-Ullbl49GfZE5n1AciFSuycgb1rZIzZXIiU~K~iOuuG~ZkruznFWlDiO-s3QSnp8jVMcvJkBdB-jCqk95sjuA~Gm3O5kcnob8QlU4iIuS~vS6JVied6A-3-xgIfI-bNviCsbfDAUSvj2aOUuQbHY14LnY-5mnjBnDHEdzJmQKPdxcsQ-FdPINz4dgQ1AYw0sut8XJ9OwIz7J9nGPCNIzg2gaAEGS1VYlmPImI1tB3z8MHRUwJ2qb36OKa6NemN38OsyTzpkKhZINGtUoVHH6F3~vjmyOYilm-8IwuoGjj6zCh0pNKU0ViyWw8sD0knXIiQNbO5vhyvw__&Key-Pair-Id=K23EPIM3SQEY3Y";
      break;
    case "prod":
      src =
        "https://d1snzrtxgdo8c5.cloudfront.net/OCP/2024/UI/OCP_Calypso_2024-Rapport_National.pptx?Expires=1808140522&Signature=M0UOCOld-sRmY8xplWdYlWDS2mPtssIT3BwkuG0LSx2OaigaqT5o6ALm4tS6qxraFhCMJY1qPF-MioImrJe28J0PPBORFRt1M6HuRk6WmQ-PklyFggy4UODwostPLmvrHsajxaAOHDu-TEiJY~L8uA4AYJK69-4v0Mbd8AlHXRrOQfFIBKmjF4vaffSFCSR3alouXo0bUinz1yiakkbpiWTUgGwn1tJjVKKRnVwSXpyfOm2xmMsqka1kfrxOuDUl~ov6s3z4zjExq6InsKIP5ne84PZ0dllYHNmsoXaR8KpKPNVaBXO9hY80y3l0TaTHDzh9VDQb8kmwrBiaK7Q2JA__&Key-Pair-Id=K3Q7KU9K8DFKFP";
      break;
    default:
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2024/UI/OCP_Calypso_2024-Rapport_National.pptx?Expires=1808141055&Signature=cl2H7Mbx8UI-Ullbl49GfZE5n1AciFSuycgb1rZIzZXIiU~K~iOuuG~ZkruznFWlDiO-s3QSnp8jVMcvJkBdB-jCqk95sjuA~Gm3O5kcnob8QlU4iIuS~vS6JVied6A-3-xgIfI-bNviCsbfDAUSvj2aOUuQbHY14LnY-5mnjBnDHEdzJmQKPdxcsQ-FdPINz4dgQ1AYw0sut8XJ9OwIz7J9nGPCNIzg2gaAEGS1VYlmPImI1tB3z8MHRUwJ2qb36OKa6NemN38OsyTzpkKhZINGtUoVHH6F3~vjmyOYilm-8IwuoGjj6zCh0pNKU0ViyWw8sD0knXIiQNbO5vhyvw__&Key-Pair-Id=K23EPIM3SQEY3Y";
  }
  return src;
})();

export const baseGuidePath = (() => {
  let src;
  switch (process.env.REACT_APP_ENV) {
    case "development":
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2023/Guide_utilisateur_OCP_V2.docx?Expires=1997942877&Signature=POcpfVKAlrP27MZSFQBM5eKK5s9iD-egWuiFB1WsVcZ0n26O8ETnUsOTk6LXq5vVR9sc4F0a6WTLdQUfi7IKUGWKSj5v3WpA8DAcuojt48~0wzpvbviEI68DoS7uThbig79VajHxsbJ4kWRF2JjKg~k43nw-dgLQZZHNpqPEsvbi2IuNa45bPK28MXbpMfd-YE2sEIKFYIfbbxoEgNrfqWjaOsAYr53G191Cq-5x-j4LEQIEN4L5-NB7IwJIFYl5ywzSdfzrEjTEfR18zDzwnPRt1DljM-u0GDFdAuoNr~W-HPFfEKndxqt1hJ3emCodkqIqkCxAZyjwXarXXtRDBw__&Key-Pair-Id=K23EPIM3SQEY3Y";
      break;
    case "qa":
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2023/Guide_utilisateur_OCP_V2.docx?Expires=1997942877&Signature=POcpfVKAlrP27MZSFQBM5eKK5s9iD-egWuiFB1WsVcZ0n26O8ETnUsOTk6LXq5vVR9sc4F0a6WTLdQUfi7IKUGWKSj5v3WpA8DAcuojt48~0wzpvbviEI68DoS7uThbig79VajHxsbJ4kWRF2JjKg~k43nw-dgLQZZHNpqPEsvbi2IuNa45bPK28MXbpMfd-YE2sEIKFYIfbbxoEgNrfqWjaOsAYr53G191Cq-5x-j4LEQIEN4L5-NB7IwJIFYl5ywzSdfzrEjTEfR18zDzwnPRt1DljM-u0GDFdAuoNr~W-HPFfEKndxqt1hJ3emCodkqIqkCxAZyjwXarXXtRDBw__&Key-Pair-Id=K23EPIM3SQEY3Y";
      break;
    case "prod":
      src =
        "https://d1snzrtxgdo8c5.cloudfront.net/OCP/2023/Guide_utilisateur_OCP_V2.docx?Expires=1997942553&Signature=TLEIebNyQmflsDRiQwXlsi3-pN--YS7fZFn9Jc3kjSj0w~aoMyDqv~Beh5RQy6EJNZTZ~bIoJu4MidbqiAEqXoJKj-Jlep~ncxuVruhegLJdvU0ElVCM0RgtGIkVvHffkBgoxNP4hDOMEO364hvChoqbIyghH3cZQHy83Gw393Zy9hiOUhOv5SkKAgC7sk9PSBbHoUy7aZRJDVCWMrlvwp0oOZi1r6x92kW1OYvl4me2PD-3dCLKZ3h8SaC46I~ofXkhG9CyzECi4Zp1XN-8xve34SQnB6cjOStKMkCz6KMleflI25e3Smd8s2z7c3HhppOd93XQIdenyQlB4bYy~A__&Key-Pair-Id=K3Q7KU9K8DFKFP";
      break;
    default:
      src =
        "https://d3r0nifdeupn65.cloudfront.net/OCP/2023/Guide_utilisateur_OCP_V2.docx?Expires=1997942877&Signature=POcpfVKAlrP27MZSFQBM5eKK5s9iD-egWuiFB1WsVcZ0n26O8ETnUsOTk6LXq5vVR9sc4F0a6WTLdQUfi7IKUGWKSj5v3WpA8DAcuojt48~0wzpvbviEI68DoS7uThbig79VajHxsbJ4kWRF2JjKg~k43nw-dgLQZZHNpqPEsvbi2IuNa45bPK28MXbpMfd-YE2sEIKFYIfbbxoEgNrfqWjaOsAYr53G191Cq-5x-j4LEQIEN4L5-NB7IwJIFYl5ywzSdfzrEjTEfR18zDzwnPRt1DljM-u0GDFdAuoNr~W-HPFfEKndxqt1hJ3emCodkqIqkCxAZyjwXarXXtRDBw__&Key-Pair-Id=K23EPIM3SQEY3Y";
  }
  return src;
})();

export const SECTION = {
  SATISFACTION_GLOBALE: "Q6",
  TAUX_LEFT: "Q51BIS",
  TAUX_RIGHT: "Q51",
  RECOMMANDATION: "Q30_1",
};
