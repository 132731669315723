import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 1000 * 60 * 10;

let TOKEN_STORAGE_KEY = "ocp_token";

let env;

if (process.env.REACT_APP_ENV === "local") {
  env = {
    API_BASE_URL: "http://localhost:7154",
    API_LOCAL_URL: "https://localhost:8081",
  };
  TOKEN_STORAGE_KEY += "_local";
} else if (process.env.REACT_APP_ENV === "qa") {
  env = {
    API_BASE_URL: "http://ocp.custdashqa.tolunainsights-internal.com",
  };
  TOKEN_STORAGE_KEY += "_qa";
} else {
  env = {
    API_BASE_URL: "https://ocp-satisfaction-calypso.com",
  };
}

function request(call, payload) {
  return call(payload)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}

function get(call) {
  return call()
    .then(function (response) {
      console.log(response);
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}

/* Login and Token Retrieval functions */
export const loginUser = (credentials) => {
  return request(loginRequest, credentials)
    .then((token) => {
      saveToken(token);
      return { isAuthenticated: true, message: "" };
    })
    .catch((error) => {
      return Promise.reject({
        isAuthenticated: false,
        message: error.response.data.error_description,
      });
    });
};
const loginRequest = (credentials) => {
  const parameters = {
    email: credentials.email,
    password: credentials.pass,
  };

  return axiosInstance.post(`${env.API_BASE_URL}/api/login/auth`, parameters);
};
/* ______________________________ */

/* LocalStorage token saving, retrieval and removal */
const saveToken = (token) => {
  let expiryDate = new Date();
  expiryDate.setSeconds(expiryDate.getSeconds() + token.expires_in);
  const objectToSave = {
    token: token.access_token,
    expiryDate: expiryDate.getTime(),
  };

  localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(objectToSave));
  setAuthHeaderFromStorage(token.access_token);
};
/* Called on page refresh so that the token is added to each subsequent request */
export const checkAuthStatus = () => {
  if (localStorage[TOKEN_STORAGE_KEY]) {
    const savedAuthData = JSON.parse(localStorage.getItem(TOKEN_STORAGE_KEY));
    const currentDate = new Date();

    //Check for token expiration validity
    if (currentDate.getTime() < parseInt(savedAuthData.expiryDate)) {
      setAuthHeaderFromStorage(savedAuthData.token);
      return true;
    }
    return false;
  }
  return false;
};
export const setAuthHeaderFromStorage = (token) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
export const resetToken = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  delete axiosInstance.defaults.headers.common.Authorization;
};
/* ______________________________ */

/* User reports request */
export const userReportsRequest = () => {
  return axiosInstance.get(`${env.API_BASE_URL}/api/Report/UserReports`);
};
export const fetchUserReportsRequest = () => get(userReportsRequest);

/* Userdetails request */

export const userDetailsRequest = () => {
  return axiosInstance.get(`${env.API_BASE_URL}/api/User/UserDetails`);
};
export const fetchUserDetailsRequest = () => get(userDetailsRequest);

// BarChart request

export const barChartRequest = (id) => {
  return axiosInstance.post(`${env.API_BASE_URL}/api/Report/BarChartResults`, id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const fetchBarChartRequest = (id) => request(barChartRequest, id);
// Main results request

export const mainResultsRequest = (id) => {
  return axiosInstance.post(
    `${env.API_BASE_URL}/api/Report/DashboardResults`,
    id,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
export const fetchMainResultsRequest = (id) => request(mainResultsRequest, id);

// ReportID request:

export const loadReportId = (data) => {
  const dataToSend = {
    ReportId: data.id,
    TypeId: data.type,
  };

  return axiosInstance.post(
    `${env.API_BASE_URL}/api/Report/ReportLog`,
    dataToSend
  );
};

export const fetchReportId = (data) => request(loadReportId, data);

// Export User log Request

export const exportUserLogRequest = () => {
  return axiosInstance.get(`${env.API_BASE_URL}/api/Export/UserLogExport`);
};

export const fetchExportUserLogRequest = () => get(exportUserLogRequest);

// Export Dashboard

export const dashboardExportRequest = (data) => {
  return axiosInstance.post(
    `${env.API_BASE_URL}/api/Export/DashboardExport`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const fetchDashboardExportRequest = (data) =>
  request(dashboardExportRequest, data);
