import { connect } from "react-redux";

import { getUserDetails, setUserDetails } from "../data/actions";
import { setAuthStatus } from "../../Main/Login/data/actions";
import { setBarChart} from "../../Main/Home/MainResultsSection/data/actions";

import UserHead from "./UserHead.page"

const mapStateToProps = state => ({
    fullname: state.user.userDetails.Fullname
});

const mapDispatchToProps = {
    getUserDetails,
    setAuthStatus,
    setBarChart,
    setUserDetails
};

const UserHeadContainer = connect(
)(UserHead);

export default connect(mapStateToProps, mapDispatchToProps)(UserHeadContainer);
