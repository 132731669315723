
export const SET_BARCHART = "@@/SET_BARCHART";
export const SET_SECTION_DATA = "@@/SET_SECTION_DATA";

export const GET_MAIN_RESULTS_DATA = "@@/GET_MAIN_RESULTS_DATA";

export const setBarChart = (data) => ({
    type: SET_BARCHART,
    payload: data
});

export const setSectionData = (data) => ({
    type: SET_SECTION_DATA,
    payload: data
});

export const getMainResultsData = (data) => ({
    type: GET_MAIN_RESULTS_DATA,
    payload: data
});
